<template>
    <div class="blind">
        <h5header :name="$t('reward.desc4')" :right="false" />
        <div class="banner flex">
            <div class="banner-name">
                <p>{{ $t('reward.desc1') }}</p>
                <div>{{ $t('reward.desc2') }}</div>
                <p>{{ $t('reward.desc3') }}</p>
            </div>
            <img src="../../public/images/reward/img.png" alt="" />
        </div>

        <div class="reward-title flex">
            <img src="../../public/images/new/inviteicon6.png" alt="">
            <div>{{ $t('reward.desc4') }}</div>
            <img src="../../public/images/new/inviteicon5.png" alt="">
        </div>
        <div class="reward-desc">{{ $t('reward.desc5') }}</div>
        <div class="reward-nav">
            <div class="nav-con flex">
                <div :class="{ actnav: act == 1 }" @click="handlerAct(1)">{{ $t('reward.desc6') }}</div>
                <div :class="{ actnav: act == 0 }" @click="handlerAct(0)">{{ $t('reward.desc7') }}</div>
                <div :class="{ actnav: act == 2 }" @click="handlerAct(2)"> {{ $t('reward.desc8') }}</div>
            </div>
        </div>
        <div class="bilnd-con">

            <div class="reword-list lenwid flex" v-if="blindInfo.actd == act" :class="{ 'lenwid': act == 2 }">
                <div class="tag" v-if="act == 1">
                    <p>{{ $t('reward.desc52') }}</p>
                </div>
                <div class="list-logo">
                    <img src="../../public/images/reward/icon1.png" alt="" />
                </div>
                <div class="list-info">
                    <div class="info-name flex">
                        <div>{{ $t('reward.desc9') }}</div>
                        <!-- <p>{{ $t('reward.desc18') }}</p> -->
                    </div>
                    <div class="info-desc flex">
                        <div class="desc">
                            <div>{{ $t('reward.desc10') }}</div>
                            <p>{{ blindInfo.landCount }}/1</p>
                        </div>
                        <div class="desc">
                            <div>{{ $t('reward.desc11') }}</div>
                            <p>{{ $t('reward.desc19', { n1: blindInfo.dayDrawCuount }) }}</p>
                        </div>
                        <div class="desc">
                            <div v-if="act != 0">{{ $t('reward.desc12') }}</div>
                            <div v-else>{{ $t('reward.desc53') }}</div>
                            <p>{{ blindInfo.datetime }}(GST)</p>
                        </div>
                        <div class="desc">
                            <div>{{ $t('reward.desc13') }}</div>
                            <p>{{ $t('blind.desc59', { n1: blindInfo.drawCount }) }}</p>
                        </div>
                        <div class="desc">
                            <div>{{ $t('reward.desc14') }}</div>
                            <p>{{ blindInfo.sumAmount }} DU</p>
                        </div>
                    </div>
                </div>
                <div class="list-btn flex">
                    <div class="bg1 flexcenter" v-if="act == 1" @click="$router.push('/blind')">
                        {{ $t('reward.desc15') }}
                    </div>
                    <div class="bg4 flexcenter" v-else-if="act == 0">
                        {{ $t('reward.desc15') }}
                    </div>
                    <div class="bg2 flexcenter" @click="$router.push('/market')" v-if="act == 1">
                        {{ $t('reward.desc16') }}
                    </div>
                    <div class="bg4 flexcenter" v-else-if="act == 0">
                        {{ $t('reward.desc16') }}
                    </div>
                    <div class="bg3 flexcenter" @click="showRules(1)">
                        {{ $t('reward.desc17') }}
                    </div>
                </div>
            </div>
            <div class="reword-list linebg2 flex" v-if="recharge.act == act" :class="{ 'lenwid': act == 2 }">
                <div class="tag" v-if="act == 1">
                    <p>{{ $t('reward.desc52') }}</p>
                </div>
                <div class="list-logo">
                    <img src="../../public/images/reward/icon2.png" alt="" />
                </div>
                <div class="list-info">
                    <div class="info-name flex">
                        <div>{{ $t('reward.desc20') }}</div>

                    </div>
                    <div class="info-desc flex">
                        <div class="desc">
                            <div>{{ $t('reward.desc11') }}</div>
                            <p>{{ $t('reward.desc23', { n1: recharge.ratio * 100 }) }}</p>
                        </div>
                        <div class="desc">

                            <div v-if="act != 0">{{ $t('reward.desc12') }}</div>
                            <div v-else>{{ $t('reward.desc53') }}</div>
                            <p class="flex">
                                <van-count-down :time="recharge.time">
                                    <template #default="timeData">
                                        <span class="block" v-if="timeData.days < 10">0{{ timeData.days }}</span>
                                        <span class="block" v-else>{{ timeData.days }}</span>
                                        <span class="colon">{{ $t('blind.desc22') }}</span>
                                        <span class="block" v-if="timeData.hours < 10">0{{ timeData.hours }}</span>
                                        <span class="block" v-else>{{ timeData.hours }}</span>
                                        <span class="colon">{{ $t('blind.desc23') }}</span>
                                        <span class="block" v-if="timeData.minutes < 10">0{{ timeData.minutes }}</span>
                                        <span class="block" v-else>{{ timeData.minutes }}</span>
                                        <span class="colon">{{ $t('blind.desc24') }}</span>
                                        <span class="block" v-if="timeData.seconds < 10">0{{ timeData.seconds }}</span>
                                        <span class="block" v-else>{{ timeData.seconds }}</span>
                                        <span class="colon">{{ $t('blind.desc25') }}</span>
                                    </template>
                                </van-count-down>
                            </p>
                        </div>

                        <div class="desc">
                            <div>{{ $t('reward.desc21') }}</div>
                            <p>{{ recharge.allAmount }} DU</p>
                        </div>
                        <div class="desc">
                            <div>{{ $t('reward.desc22') }}</div>
                            <p>{{ recharge.returnAmount || 0 }} DU</p>
                        </div>
                    </div>
                </div>
                <div class="list-btn flex">

                    <div class="bg1 flexcenter"
                        @click="$router.push(`/recharge?id=${recharge.awardCoinId}&type=${recharge.awardAccountType}`)"
                        v-if="act == 1">
                        {{ $t('reward.desc24') }}
                    </div>
                    <div class="bg4 flexcenter" v-else-if="act == 0">
                        {{ $t('reward.desc24') }}
                    </div>
                    <div class="bg3 flexcenter" @click="showRules(2)">
                        {{ $t('reward.desc17') }}
                    </div>
                </div>
            </div>

            <div class="reword-list linebg3 lenwid flex" v-if="marketInfo.act == act" :class="{ 'lenwid': act == 2 }">
                <div class="tag" v-if="act == 1">
                    <p>{{ $t('reward.desc52') }}</p>
                </div>
                <div class="list-logo">
                    <img src="../../public/images/reward/icon3.png" alt="" />
                </div>
                <div class="list-info">
                    <div class="info-name flex">
                        <div>{{ $t('reward.desc25') }}</div>
                    </div>
                    <div class="info-desc flex">

                        <div class="desc">
                            <div>{{ $t('reward.desc11') }}</div>
                            <p>DU</p>
                        </div>
                        <div class="desc">
                            <div v-if="act != 0">{{ $t('reward.desc12') }}</div>
                            <div v-else>{{ $t('reward.desc53') }}</div>
                            <p class="flex">
                                <van-count-down :time="marketInfo.time">
                                    <template #default="timeData">
                                        <span class="block" v-if="timeData.days < 10">0{{ timeData.days }}</span>
                                        <span class="block" v-else>{{ timeData.days }}</span>
                                        <span class="colon">{{ $t('blind.desc22') }}</span>
                                        <span class="block" v-if="timeData.hours < 10">0{{ timeData.hours }}</span>
                                        <span class="block" v-else>{{ timeData.hours }}</span>
                                        <span class="colon">{{ $t('blind.desc23') }}</span>
                                        <span class="block" v-if="timeData.minutes < 10">0{{ timeData.minutes }}</span>
                                        <span class="block" v-else>{{ timeData.minutes }}</span>
                                        <span class="colon">{{ $t('blind.desc24') }}</span>
                                        <span class="block" v-if="timeData.seconds < 10">0{{ timeData.seconds }}</span>
                                        <span class="block" v-else>{{ timeData.seconds }}</span>
                                        <span class="colon">{{ $t('blind.desc25') }}</span>
                                    </template>
                                </van-count-down>
                            </p>
                        </div>
                        <div class="desc">
                            <div>{{ $t('reward.desc26') }}</div>
                            <p v-if="marketInfo.landWeekSellCount > 0">{{ $t('blind.desc59', {
                                n1:
                                    marketInfo.landWeekSellCount
                            }) }}</p>
                            <p v-else>0</p>
                        </div>
                        <div class="desc">
                            <div>{{ $t('reward.desc27') }}</div>
                            <p>{{ marketInfo.sellOutCount }}</p>
                        </div>
                        <div class="desc">
                            <div>{{ $t('reward.desc28') }}</div>
                            <p>{{ marketInfo.sumAmount }} DU</p>
                        </div>
                    </div>
                </div>
                <div class="list-btn flex">
                    <!-- <div class="bg1 flexcenter" @click="$router.push('/reward/pledge?act=2')" v-if="act == 1">
                        {{ $t('reward.desc29') }}
                    </div>
                    <div class="bg4 flexcenter" v-else-if="act == 0">
                        {{ $t('reward.desc29') }}
                    </div> -->
                    <div class="bg1 flexcenter" @click="$router.push('/reward/pledge?act=1')" v-if="act == 1">
                        {{ $t('reward.desc30') }}
                    </div>
                    <div class="bg4 flexcenter" v-else-if="act == 0">
                        {{ $t('reward.desc30') }}
                    </div>
                    <div class="bg1 flexcenter" @click="$router.push('/manage')" v-if="act == 1">
                        {{ $t('reward.desc31') }}
                    </div>
                    <div class="bg4 flexcenter" v-else-if="act == 0">
                        {{ $t('reward.desc31') }}
                    </div>
                    <div class="bg3 flexcenter" @click="showRules(3)">
                        {{ $t('reward.desc17') }}
                    </div>
                </div>
            </div>
            <div class="reword-list linebg4 flex" v-if="reinvest.act == act" :class="{ 'lenwid': act == 2 }">
                <div class="tag" v-if="act == 1">
                    <p>{{ $t('reward.desc52') }}</p>
                </div>
                <div class="list-logo">
                    <img src="../../public/images/reward/icon4.png" alt="" />
                </div>
                <div class="list-info">
                    <div class="info-name flex">
                        <div>{{ $t('reward.desc35') }}</div>
                    </div>
                    <div class="info-desc flex">

                        <div class="desc">
                            <div>{{ $t('reward.desc11') }}</div>
                            <p>{{ reinvest.awardCoinName }}</p>
                        </div>
                        <div class="desc">
                            <div v-if="act != 0">{{ $t('reward.desc12') }}</div>
                            <div v-else>{{ $t('reward.desc53') }}</div>
                            <p class="flex">
                                <van-count-down :time="reinvest.time">
                                    <template #default="timeData">
                                        <span class="block" v-if="timeData.days < 10">0{{ timeData.days }}</span>
                                        <span class="block" v-else>{{ timeData.days }}</span>
                                        <span class="colon">{{ $t('blind.desc22') }}</span>
                                        <span class="block" v-if="timeData.hours < 10">0{{ timeData.hours }}</span>
                                        <span class="block" v-else>{{ timeData.hours }}</span>
                                        <span class="colon">{{ $t('blind.desc23') }}</span>
                                        <span class="block" v-if="timeData.minutes < 10">0{{ timeData.minutes }}</span>
                                        <span class="block" v-else>{{ timeData.minutes }}</span>
                                        <span class="colon">{{ $t('blind.desc24') }}</span>
                                        <span class="block" v-if="timeData.seconds < 10">0{{ timeData.seconds }}</span>
                                        <span class="block" v-else>{{ timeData.seconds }}</span>
                                        <span class="colon">{{ $t('blind.desc25') }}</span>
                                    </template>
                                </van-count-down>
                            </p>
                        </div>
                        <div class="desc">
                            <div>{{ $t('reward.desc36') }}</div>
                            <p>{{ reinvest.allAmount || 0 }} DU</p>
                        </div>
                        <div class="desc">
                            <div>{{ $t('reward.desc37') }}</div>
                            <p>{{ reinvest.returnAmount || 0 }} DU</p>
                        </div>
                    </div>
                </div>
                <div class="list-btn flex">
                    <div class="bg1 flexcenter" v-if="act == 1" @click="$router.push('/reward/reinvest?act=1')">
                        {{ $t('reward.desc32') }}
                    </div>
                    <div class="bg4 flexcenter" v-else-if="act == 0">
                        {{ $t('reward.desc32') }}
                    </div>

                    <div class="bg1 flexcenter" v-if="act == 1" @click="$router.push('/reward/reinvest?act=2')">
                        {{ $t('reward.desc33') }}
                    </div>
                    <div class="bg4 flexcenter" v-else-if="act == 0">
                        {{ $t('reward.desc33') }}
                    </div>

                    <div class="bg1 flexcenter" v-if="act == 1" @click="$router.push('/market')">
                        {{ $t('reward.desc34') }}
                    </div>
                    <div class="bg4 flexcenter" v-else-if="act == 0">
                        {{ $t('reward.desc34') }}
                    </div>
                    <div class="bg3 flexcenter" @click="showRules(4)">
                        {{ $t('reward.desc17') }}
                    </div>
                </div>
            </div>
            <div class="reword-list linebg2 linebg5 flex" v-if="speedInfo.act == act" :class="{ 'lenwid': act == 2 }">
                <div class="tag" v-if="act == 1">
                    <p>{{ $t('reward.desc52') }}</p>
                </div>
                <div class="list-logo">
                    <img src="../../public/images/reward/icon16.png" alt="" />
                </div>
                <div class="list-info">
                    <div class="info-name flex">
                        <div>{{ $t('market.desc184') }}</div>
                    </div>
                    <div class="info-desc flex">

                        <div class="desc">
                            <div>{{ $t('reward.desc11') }}</div>
                            <p>DU & RC</p>
                        </div>

                        <div class="desc">
                            <div>{{ $t('market.desc185') }}</div>
                            <p style="color:#fff">{{ $t('market.desc186') }}</p>
                        </div>

                    </div>
                </div>
                <div class="list-btn flex">


                    <div class="bg1 flexcenter" v-if="act == 1" @click="$router.push('/reward/speed')">
                        {{ $t('market.desc187') }}
                    </div>
                    <div class="bg4 flexcenter" v-else-if="act == 0">
                        {{ $t('market.desc187') }}
                    </div>
                    <div class="bg3 flexcenter" @click="showRate = true">
                        {{ $t('reward.desc17') }}
                    </div>
                </div>
            </div>
            <div class="reword-list linebg6 flex" v-if="planetInfo.act == act" :class="{ 'lenwid': act == 2 }">
                <div class="tag" v-if="act == 1">
                    <p>{{ $t('reward.desc52') }}</p>
                </div>
                <div class="list-logo">
                    <img src="../../public/images/reward/planetimg.png" alt="" />
                </div>
                <div class="list-info">
                    <div class="info-name flex">
                        <div>{{ $t('reward.desc171') }}！</div>
                    </div>
                    <div class="info-desc flex">

                        <!-- <div class="desc">
                            <div>{{ $t('reward.desc172') }}</div>
                            <p>{{ planetInfo.notQuantityGiven }}/{{ planetInfo.conditionNum }}</p>
                        </div> -->
                      
                        <div class="desc">
                            <div>{{ $t('reward.desc173') }}</div>
                            <p style="color:#fff">{{ $t('reward.desc215') }}</p>
                        </div>
                      
                        <div class="desc">
                            <div>{{ $t('reward.desc217') }}</div>
                            <p>{{ $t('reward.desc218',{n1:planetInfo.allBuyNum}) }}</p>
                        </div>
                        <div class="desc">
                            <div>{{ $t('reward.desc216') }}</div>
                            <p>{{ $t('reward.desc175', { n1: planetInfo.quantityGiven }) }}</p>
                        </div>
                        <div class="desc">
                            <div v-if="act != 0">{{ $t('reward.desc12') }}</div>
                            <div v-else>{{ $t('reward.desc53') }}</div>
                            <p class="flex">
                                <van-count-down :time="planetInfo.time">
                                    <template #default="timeData">
                                        <span class="block" v-if="timeData.days < 10">0{{ timeData.days }}</span>
                                        <span class="block" v-else>{{ timeData.days }}</span>
                                        <span class="colon">{{ $t('blind.desc22') }}</span>
                                        <span class="block" v-if="timeData.hours < 10">0{{ timeData.hours }}</span>
                                        <span class="block" v-else>{{ timeData.hours }}</span>
                                        <span class="colon">{{ $t('blind.desc23') }}</span>
                                        <span class="block" v-if="timeData.minutes < 10">0{{ timeData.minutes }}</span>
                                        <span class="block" v-else>{{ timeData.minutes }}</span>
                                        <span class="colon">{{ $t('blind.desc24') }}</span>
                                        <span class="block" v-if="timeData.seconds < 10">0{{ timeData.seconds }}</span>
                                        <span class="block" v-else>{{ timeData.seconds }}</span>
                                        <span class="colon">{{ $t('blind.desc25') }}</span>
                                    </template>
                                </van-count-down>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="list-btn flex">


                    <div class="bg1 flexcenter" v-if="act == 1" @click="$router.push('/node')">
                        {{ $t('reward.desc197') }}
                    </div>
                    <div class="bg4 flexcenter" v-else-if="act == 0">
                        {{ $t('reward.desc197') }}
                    </div>
                    <div class="bg2 flexcenter" @click="$router.push('/reward/planet')">
                        {{ $t('reward.desc178') }}
                    </div>
                    <div class="bg3 flexcenter" @click="showRules(7)">
                        {{ $t('reward.desc17') }}
                    </div>
                </div>
            </div>
            <div class="reword-list linebg7 flex" v-if="landInfo.act == act" :class="{ 'lenwid': act == 2 }">
                <div class="tag" v-if="act == 1">
                    <p>{{ $t('reward.desc52') }}</p>
                </div>
                <div class="list-logo">
                    <img src="../../public/images/reward/icon19.png" alt="" />
                </div>
                <div class="list-info">
                    <div class="info-name flex">
                        <div>{{ $t('reward.desc210') }}</div>
                    </div>
                    <div class="info-desc flex">

                        <!-- <div class="desc">
                            <div>{{ $t('reward.desc172') }}</div>
                            <p>{{ landInfo.notQuantityGiven }}/{{ landInfo.conditionNum }}</p>
                        </div> -->

                        <div class="desc">
                            <div>{{ $t('reward.desc173') }}</div>
                            <p style="color:#fff">{{ $t('reward.desc214') }}</p>
                        </div>
                        <div class="desc">
                            <div>{{ $t('reward.desc217') }}</div>
                            <p>{{ $t('reward.desc175',{n1:landInfo.allBuyNum}) }}</p>
                        </div>
                        <div class="desc">
                            <div>{{ $t('reward.desc216') }}</div>
                            <p>{{ $t('reward.desc175', { n1: landInfo.quantityGiven }) }}</p>
                        </div>
                        <div class="desc">
                            <div v-if="act != 0">{{ $t('reward.desc12') }}</div>
                            <div v-else>{{ $t('reward.desc53') }}</div>
                            <p class="flex">
                                <van-count-down :time="landInfo.time">
                                    <template #default="timeData">
                                        <span class="block" v-if="timeData.days < 10">0{{ timeData.days }}</span>
                                        <span class="block" v-else>{{ timeData.days }}</span>
                                        <span class="colon">{{ $t('blind.desc22') }}</span>
                                        <span class="block" v-if="timeData.hours < 10">0{{ timeData.hours }}</span>
                                        <span class="block" v-else>{{ timeData.hours }}</span>
                                        <span class="colon">{{ $t('blind.desc23') }}</span>
                                        <span class="block" v-if="timeData.minutes < 10">0{{ timeData.minutes }}</span>
                                        <span class="block" v-else>{{ timeData.minutes }}</span>
                                        <span class="colon">{{ $t('blind.desc24') }}</span>
                                        <span class="block" v-if="timeData.seconds < 10">0{{ timeData.seconds }}</span>
                                        <span class="block" v-else>{{ timeData.seconds }}</span>
                                        <span class="colon">{{ $t('blind.desc25') }}</span>
                                    </template>
                                </van-count-down>
                            </p>
                        </div>
                       
                    </div>
                </div>
                <div class="list-btn flex">


                    <div class="bg1 flexcenter" v-if="act == 1" @click="$router.push('/market')">
                        {{ $t('reward.desc177') }}
                    </div>
                    <div class="bg4 flexcenter" v-else-if="act == 0">
                        {{ $t('reward.desc177') }}
                    </div>
                    <div class="bg2 flexcenter" @click="$router.push('/reward/land')">
                        {{ $t('reward.desc178') }}
                    </div>
                    <div class="bg3 flexcenter" @click="showRules(6)">
                        {{ $t('reward.desc17') }}
                    </div>
                </div>
            </div>

            <div class="no-data" v-if="showNull">
                <img src="../../public/images/no-data.png" alt="" />
                <span>{{ $t("record.desc13") }}</span>
            </div>
        </div>
        <!-- 活动规则 -->
        <van-popup v-model:show="showNotice" :close-on-click-overlay="false">
            <div class="notice-con">
                <img src="../../public/images/new/closeicon2.png" alt="" class="close" @click="showNotice = false" />
                <div class="notice-name">{{ $t("reward.desc17") }}：</div>
                <div class="rules-list" v-if="rulesId == 1">
                    <div>{{ $t('blind.desc9') }}</div>
                    <div>{{ $t('blind.desc10') }}{{ configInfo.startTime }} - {{ configInfo.endTime }} (GST)</div>
                    <div v-html="$t('blind.desc11', { n1: configInfo.drawCount })"></div>
                    <div>{{ $t('blind.desc12') }}</div>
                    <div>{{ $t('blind.desc13') }}</div>
                    <div>{{ $t('blind.desc14') }}</div>
                    <div>{{ $t('blind.desc15', { n1: configInfo.drawCount }) }}</div>
                    <div v-if="configInfo.list != undefined">{{ $t('blind.desc16') }}
                        <span v-for="(item, index) in configInfo.list" :key="item.id">{{ item.amount }}
                            {{ configInfo.coinName }} <strong v-if="index != configInfo.list.length - 1">/</strong></span>
                        <span> {{ $t('blind.desc19', { n1: configInfo.list.length }) }}</span>
                    </div>
                    <div>{{ $t('blind.desc17') }}{{ accountTypeName }}</div>
                    <div>{{ $t('blind.desc18') }}</div>
                </div>
                <div class="rules-list" v-else-if="rulesId == 2">
                    <div>{{ $t('asset.desc151') }} {{ common.formatDate(configInfo1.startTime) }} - {{
                        common.formatDate(configInfo1.endTime) }}（GST）</div>
                    <div>{{ $t('asset.desc152', { n1: 'USDT' }) }}</div>
                    <div>{{ $t('asset.desc153', { n1: configInfo1.ratio * 100, n2: 'USDT' }) }}</div>
                    <div>{{ $t('asset.desc154', { n1: configInfo1.awardCoinName }) }}</div>
                    <div>{{ $t('asset.desc155') }} <span v-if="configInfo1.awardAccountType == 1">{{ $t('new.desc7')
                    }}</span>
                        <span v-else-if="configInfo1.awardAccountType == 2">{{ $t('new.desc8') }}</span>
                        <span v-else>{{ $t('new.desc9') }}</span>
                    </div>
                    <div>{{ $t('asset.desc156') }}</div>
                </div>
                <div class="rules-list" v-else-if="rulesId == 3">
                    <!-- <div>{{ $t('reward.desc30') }}</div> -->
                    <div>{{ $t('reward.desc40') }} <label>{{ weekTime1 }} (GST)</label>
                    </div>
                    <div>{{ $t('reward.desc41') }}</div>
                    <div>{{ $t('reward.desc42') }}</div>
                    <div>{{ $t('reward.desc43') }}</div>
                </div>
                <div class="rules-list" v-else-if="rulesId == 7">
                    <div class="speed-desc" >{{ $t('reward.desc212') }}</div>
                   <div class="speed-desc">{{ $t('reward.desc198') }}</div>
                   <div class="speed-desc">{{ $t('reward.desc181') }}</div>
                   <div class="speed-desc">{{ $t('reward.desc199') }}</div>
                   <div class="speed-desc">{{ $t('reward.desc200') }}</div>
                   <div class="speed-desc">{{ $t('reward.desc201') }}</div>
                   <div class="speed-desc">{{ $t('reward.desc213') }}</div>
                   <!-- <div class="speed-desc">{{ $t('reward.desc220') }}</div> -->
                   <div class="speed-desc">{{ $t('reward.desc186') }}</div>
                   <div class="speed-desc"><label>{{ planetInfo.startTime }} - {{ planetInfo.endTime }} (GST)</label> </div>
                   <div class="speed-desc">{{ $t('reward.desc187') }}</div>
                   <div class="speed-desc">{{ $t('reward.desc202') }}</div>
                   <div class="speed-desc">{{ $t('reward.desc189') }}</div>
                   <div class="speed-desc">{{ $t('reward.desc203') }}</div>
                   <div class="speed-desc">{{ $t('reward.desc191') }}</div>
                   <div class="speed-desc">{{ $t('reward.desc204') }}</div>
                   <div class="speed-desc">{{ $t('reward.desc205') }}</div>
                   <div class="speed-desc">{{ $t('reward.desc206') }}</div>
                   <div class="speed-desc">{{ $t('reward.desc207') }}</div>
                   <div class="speed-desc">{{ $t('reward.desc208') }}</div>
                   <div class="speed-desc">{{ $t('reward.desc219') }}</div>
                </div>
                <div class="rules-list" v-else-if="rulesId == 6">
                    <div class="speed-desc">{{ $t('reward.desc179') }}</div>

                    <div class="speed-desc">{{ $t('reward.desc181') }}</div>
                    <div class="speed-desc">{{ $t('reward.desc182') }}</div>
                    <div class="speed-desc">2.2 <label>{{ $t('reward.desc183') }}</label></div>
                    <div class="speed-desc">2.3 <label>{{ $t('reward.desc184') }}</label></div>
                    <div class="speed-desc">{{ $t('reward.desc185') }}</div>
                    <div class="speed-desc">{{ $t('reward.desc186') }}</div>
                    <div class="speed-desc"><label>{{ landInfo.startTime }} - {{ landInfo.endTime }} (GST)</label> </div>
                    <div class="speed-desc">{{ $t('reward.desc187') }}</div>
                    <div class="speed-desc">{{ $t('reward.desc188') }}</div>
                    <div class="speed-desc">{{ $t('reward.desc189') }}</div>
                    <div class="speed-desc">{{ $t('reward.desc190') }}</div>
                    <div class="speed-desc">{{ $t('reward.desc191') }}</div>
                    <div class="speed-desc" v-html="$t('reward.desc192')"></div>
                    <div class="speed-desc" v-html="$t('reward.desc193')"></div>
                    <div class="speed-desc" v-html="$t('reward.desc194')"></div>
                </div>
                <div class="rules-list" v-else>
                    <div
                        v-html="$t('reward.desc147', { n1: reinvest.activityParamOne * 100, n2: reinvest.activityParamThree * 100, n3: reinvest.activityParamTwo * 100 })">
                    </div>
                    <div>{{ $t('reward.desc148') }}<label>{{ common.formatDate(reinvest.startTime) }} - {{
                        common.formatDate(reinvest.endTime) }}(GST)</label> </div>
                    <div>{{ $t('reward.desc149') }}</div>
                    <div>{{ $t('reward.desc150') }}</div>
                    <div>{{ $t('reward.desc151') }}</div>
                    <div class="rules-table">
                        <div class="table-title flex">
                            <div>{{ $t('reward.desc152') }}</div>
                            <div>{{ $t('reward.desc141') }}</div>
                            <div>{{ $t('reward.desc159') }}</div>
                        </div>
                        <div class="tablle-info flex" v-for="item in issueList" :key="item.num">
                            <div>{{ $t('reward.desc153', { n1: item.num }) }}</div>
                            <div>{{ item.starttime }} {{ $t('reward.desc160') }} {{ item.endtime }} (GST)</div>
                            <div>{{ item.sendtime }}</div>
                        </div>
                    </div>
                    <div>{{ $t('reward.desc154') }}</div>
                    <div class="montitle flex">
                        <div>{{ $t('reward.desc141') }}</div>
                        <div>{{ $t('reward.desc159') }}</div>
                    </div>
                    <div class="montime flex">
                        <div>2023-11-24 11:00-2024-01-22 00:00 (GST)</div>
                        <div>2024-01-22 02:00</div>
                    </div>
                    <div>{{ $t('reward.desc155') }}{{ reinvest.awardCoinName }}</div>
                    <div>{{ $t('reward.desc156') }}{{ reinvest.awardAccountTypeName }}</div>
                    <div>{{ $t('reward.desc157') }}</div>
                    <div>{{ $t('reward.desc158') }}</div>
                </div>
            </div>
        </van-popup>
        <!--  -->
        <van-popup v-model:show="showRate" :close-on-click-overlay="false">
            <div class="speedcon">
                <img src="../../public/images/new/closeicon.png" alt="" class="close" @click="showRate = false" />
                <div class="speedcon-title flex">
                    <img src="../../public/images/new/speedicon.png" alt="" />
                    {{ $t('market.desc188') }}
                </div>
                <div class="speed-wrappar">
                    <div class="speed-desc">
                        {{ $t('market.desc189') }}
                    </div>
                    <div class="speed-desc">
                        {{ $t('market.desc190') }}{{ $t('market.desc221', { n1: common.formatDate(speedInfo.startTime) }) }}
                    </div>
                    <div class="speed-desc">
                        {{ $t('market.desc191') }}
                    </div>
                    <div class="rate-table">
                        <div class="table-thead flex">
                            <div class="flexcenter">{{ $t('market.desc180') }}</div>
                            <div class="flexcenter">{{ $t('market.desc181') }}</div>
                            <div class="flexcenter">{{ $t('market.desc182') }}</div>
                            <div class="flexcenter">{{ $t('market.desc183') }}</div>
                        </div>
                        <div class="table-tbody">
                            <div class="tbody-info flex" v-for="item in rateList" :key="item.id">
                                <div>{{ item.planetLandSellRatio * 100 }} %</div>
                                <div>{{ item.landReleaseRatio * 100 }} %</div>
                                <div>{{ $t('pool.desc13', { n1: item.releaseDay }) }} </div>
                                <div>{{ item.planetReleaseRatio * 100 }} %</div>
                            </div>
                        </div>
                    </div>
                    <div class="speed-desc">
                        {{ $t('market.desc192') }}
                    </div>
                    <div class="speed-desc">
                        {{ $t('market.desc193') }}
                    </div>
                    <div class="speed-desc">
                        {{ $t('market.desc194') }}
                    </div>
                    <div class="speed-desc">
                        {{ $t('market.desc195') }}
                    </div>
                    <div class="speed-desc">
                        {{ $t('market.desc196') }}
                    </div>
                    <div class="speed-desc">
                        {{ $t('market.desc197') }}
                    </div>
                    <div class="speed-desc">
                        {{ $t('market.desc198') }}
                    </div>
                    <div class="speed-desc">
                        {{ $t('market.desc199') }}
                    </div>
                    <div class="speed-desc">
                        {{ $t('market.desc200') }}
                    </div>
                    <div class="speed-desc">
                        {{ $t('market.desc201') }}
                    </div>
                    <div class="speed-desc">
                        {{ $t('market.desc202') }}
                    </div>
                </div>
                <div class="ratebtn flexcenter" @click="showRate = false">{{ $t('assetbag.desc71') }}</div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import h5header from "@/componets/h5header.vue";
export default {
    components: {
        h5header
    },
    data() {
        return {
            showRate: false,
            showNotice: false,
            act: 1,
            blindInfo: {},
            recharge: {},
            reinvest: {},
            rulesId: 1,
            accountTypeName: '',
            configInfo: {},
            configInfo1: {},
            marketInfo: {},
            configInfo2: {},
            showNull: false,
            weekTime1: '',
            speedInfo: {},
            configInfo4: {},
            issueList: [
                {
                    num: 1,
                    starttime: '2023-12-23 11:00:00',
                    endtime: '2023-12-24 23:59:59',
                    sendtime: '2023-12-25 02:00:00'
                },
                {
                    num: 2,
                    starttime: '2023-12-25 00:00:00',
                    endtime: '2024-12-31 23:59:59',
                    sendtime: '2024-01-01 02:00:00'
                },
                {
                    num: 3,
                    starttime: '2024-01-01 00:00:00',
                    endtime: '2024-01-07 23:59:59',
                    sendtime: '2024-01-08 02:00:00'
                },
                {
                    num: 4,
                    starttime: '2024-01-08 00:00:00',
                    endtime: '2024-01-14 23:59:59',
                    sendtime: '2024-01-15 02:00:00'
                },
                {
                    num: 5,
                    starttime: '2024-01-15 00:00:00',
                    endtime: '2024-01-21 23:59:59',
                    sendtime: '2024-01-22 02:00:00'
                }
            ],
            rateList: [],
            planetInfo: {},
            landInfo: {}

        }
    },
    mounted() {
        // 规则
        this.$post(this.URL.blind.config, {}).then(res => {
            if (res.code == 0) {
                this.configInfo = res.data;
                if (res.data.accountType == 1) {
                    this.accountTypeName = this.$t('new.desc7')
                } else if (res.data.accountType == 2) {
                    this.accountTypeName = this.$t('new.desc8')
                } else {
                    this.accountTypeName = this.$t('new.desc9')
                }
            }
        })
        this.$post(this.URL.assets.rechargeConfig, {
            activityType: 3
        }).then(res => {
            if (res.code == 0 && res.data != null) {
                console.log(res.data, 1111)
                let time1 = 4 * 60 * 60 * 1000, todayTime = new Date().getTime();
                res.data.datetime = this.common.formatDate(res.data.endTime)
                if (todayTime > res.data.endTime) {
                    res.data.act = 2;
                } else if (todayTime < res.data.startTime) {
                    res.data.act = 0;
                    res.data.time = res.data.startTime - todayTime;
                } else {
                    res.data.time = res.data.endTime - todayTime
                    res.data.act = 1;
                }
                res.data.startTime = res.data.startTime - time1;
                res.data.endTime = res.data.endTime - time1;
                if (res.data.awardAccountType == 1) {
                    res.data.awardAccountTypeName = this.$t('new.desc7')
                } else if (res.data.awardAccountType == 2) {
                    res.data.awardAccountTypeName = this.$t('new.desc8')
                } else {
                    res.data.awardAccountTypeName = this.$t('new.desc9')
                }
                this.configInfo4 = res.data;
                this.speedInfo = res.data;
            }
        })
        this.$post(this.URL.reward.marketConfig, {}).then(res => {
            if (res.code == 0) {
                res.data.coinName = res.data.list[0].coinName;
                if (res.data.accountType == 1) {
                    res.data.awardAccountTypeName = this.$t('new.desc7')
                } else if (res.data.accountType == 2) {
                    res.data.awardAccountTypeName = this.$t('new.desc8')
                } else {
                    res.data.awardAccountTypeName = this.$t('new.desc9')
                }
                this.configInfo2 = res.data;
                let weekday = new Date(`${this.getThisSunday()} 23:59:59`), weekdaytime = weekday.getTime(), todayTime = new Date().getTime();

                if (todayTime > localStorage.getItem('startTime') && localStorage.getItem('startTime') != null) {
                    if (weekdaytime > this.configInfo2.endTimeStamp) {
                        this.weekTime1 = `${this.getWeek(new Date(), 0, 0)} 00:00:00 - ${this.configInfo2.endTime}`;
                        return;
                    }
                    this.weekTime1 = `${this.getWeek(new Date(), 0, 0)} 00:00:00 - ${this.getThisSunday()} 23:59:59`;
                } else {
                    this.weekTime1 = `${this.common.formatDate(this.configInfo2.startTimeStamp)} - ${this.getThisSunday()} 23:59:59`;
                    localStorage.setItem('startTime', weekdaytime)

                }
            }
        })
        this.$post(this.URL.reward.planetConfig, {
            type: 2
        }).then(res => {
            if (res.code == 0) {
                let time1 = 4 * 60 * 60 * 1000, todayTime = new Date().getTime(), startTime = new Date(res.data.startTime).getTime() + time1, endTime = new Date(res.data.endTime).getTime() + time1;
            
                if (todayTime > endTime) {
                    res.data.act = 2;
                } else if (todayTime < startTime) {
                    res.data.act = 0;
                    res.data.time = startTime - todayTime;
                } else {
                    res.data.time = endTime - todayTime
                    res.data.act = 1;
                }
                this.landInfo = res.data;
            }
        })
        this.$post(this.URL.reward.planetConfig, {
            type: 1
        }).then(res => {
            if (res.code == 0) {
                let time1 = 4 * 60 * 60 * 1000, todayTime = new Date().getTime(), startTime = new Date(res.data.startTime).getTime() + time1, endTime = new Date(res.data.endTime).getTime() + time1;

                if (todayTime > endTime) {
                    res.data.act = 2;
                } else if (todayTime < startTime) {
                    res.data.act = 0;
                    res.data.time = startTime - todayTime;
                } else {
                    res.data.time = endTime - todayTime
                    res.data.act = 1;
                }
                this.planetInfo = res.data;
            }
        })
        //
        this.$post(this.URL.reward.blind, {
        }).then(res => {
            if (res.code == 0) {
                let time1 = 4 * 60 * 60 * 1000, todayTime = new Date().getTime();
                res.data.datetime = this.common.formatDate(res.data.endTime);
                if (todayTime > res.data.endTime + time1) {
                    res.data.actd = 2;
                } else if (todayTime < res.data.startTime + time1) {
                    res.data.actd = 0;
                } else {
                    res.data.actd = 1;
                }
                this.blindInfo = res.data;
            }
        })
        this.$post(this.URL.assets.rechargeConfig, {
            activityType: 1
        }).then(res => {
            if (res.code == 0 && res.data != null) {
                let time1 = 4 * 60 * 60 * 1000, todayTime = new Date().getTime();
                res.data.datetime = this.common.formatDate(res.data.endTime)
                if (todayTime > res.data.endTime) {
                    res.data.act = 2;
                } else if (todayTime < res.data.startTime) {
                    res.data.act = 0;
                    res.data.time = res.data.startTime - todayTime;
                } else {
                    res.data.time = res.data.endTime - todayTime
                    res.data.act = 1;
                }
                res.data.startTime = res.data.startTime - time1;
                res.data.endTime = res.data.endTime - time1;
                if (res.data.awardAccountType == 1) {
                    res.data.awardAccountTypeName = this.$t('new.desc7')
                } else if (res.data.awardAccountType == 2) {
                    res.data.awardAccountTypeName = this.$t('new.desc8')
                } else {
                    res.data.awardAccountTypeName = this.$t('new.desc9')
                }
                this.configInfo1 = res.data;
                this.recharge = res.data;
            }
        })
        this.$post(this.URL.reward.land, {
            activityType: 2
        }).then(res => {
            if (res.code == 0 && res.data != null) {
                let time1 = 4 * 60 * 60 * 1000, todayTime = new Date().getTime();
                res.data.startTime = res.data.startTime - time1;
                res.data.endTime = res.data.endTime - time1;
                res.data.datetime = this.common.formatDate(res.data.endTime)
                if (todayTime > res.data.endTime + time1) {
                    res.data.act = 2;
                } else if (todayTime < res.data.startTime + time1) {
                    res.data.act = 0;
                } else {
                    res.data.time = res.data.endTime + time1 - todayTime
                    res.data.act = 1;
                }
                if (res.data.awardAccountType == 1) {
                    res.data.awardAccountTypeName = this.$t('new.desc7')
                } else if (res.data.awardAccountType == 2) {
                    res.data.awardAccountTypeName = this.$t('new.desc8')
                } else {
                    res.data.awardAccountTypeName = this.$t('new.desc9')
                }

                this.reinvest = res.data;
            }
        })
        this.$post(this.URL.reward.market, {

        }).then(res => {
            if (res.code == 0) {
                let time1 = 4 * 60 * 60 * 1000, todayTime = new Date().getTime();
                res.data.datetime = this.common.formatDate(res.data.endTime)
                if (todayTime > res.data.endTime + time1) {
                    res.data.act = 2;
                } else if (todayTime < res.data.startTime + time1) {
                    res.data.time = res.data.endTime + time1 - todayTime
                    res.data.act = 0;
                } else {
                    res.data.time = res.data.endTime + time1 - todayTime
                    res.data.act = 1;
                }
                this.marketInfo = res.data;
            }
        })
        // 比例说明
        this.$post(this.URL.planet.config, {}).then(res => {
            if (res.code == 0) {
                this.rateList = res.data;
            }
        })
    },
    methods: {
        getWeek(n, type) { // 周
            let now = new Date()
            let day = now.getDay() //返回星期几的某一天;
            if (!type) {
                if (n == 1) {
                    let dayNumber = day == 0 ? 0 : 7 - day
                    now.setDate(now.getDate() + dayNumber + 1)
                } else if (n == -1) {
                    let dayNumber = day == 0 ? 6 : day - 1
                    now.setDate(now.getDate() - dayNumber - 7)
                } else {
                    let dayNumber = day == 0 ? 6 : day - 1
                    now.setDate(now.getDate() - dayNumber)
                }
            } else {
                if (n == 1) {
                    let dayNumber = day == 0 ? 0 : 7 - day
                    now.setDate(now.getDate() + dayNumber + 1 + 6) // 在周开始的日期上+6天=周结束
                } else if (n == -1) {
                    let dayNumber = day == 0 ? 6 : day - 1
                    now.setDate(now.getDate() - dayNumber - 7 + 6)
                } else {
                    let dayNumber = day == 0 ? 0 : 7 - day
                    now.setDate(now.getDate() + dayNumber)
                }
            }
            let date = now.getDate()
            let month = now.getMonth() + 1

            let s = now.getFullYear() + '-' + (month < 10 ? '0' + month : month) + '-' + (date < 10 ? '0' + date : date)
            return s
        },
        getThisSunday() {
            var date = new Date();

            let w = date.getDay();//获取一下今天是周几
            let delta = 7 - w;//算算差几天到周日
            date.setDate(date.getDate() + delta);
            date = date.toJSON()
            date = date.substring(0, 10)
            return date;
        },
        showRules(i) {
            this.rulesId = i;
            this.showNotice = true;
        },
        handlerAct(i) {
            this.act = i;
            this.showNull = false;

            if (this.blindInfo.actd != i && this.recharge.act != i && this.marketInfo.act != i && this.reinvest.act != i && this.speedInfo.act != i && this.planetInfo.act != i && this.landInfo.act != i) {
                this.showNull = true;
            }

        }
    }
}
</script>

<style lang="less" scoped>
.blind {
    width: 100%;
    padding: 96px 0 80px;
    background: url('../../public/images/new/landbg.png') center no-repeat;
    background-size: 100% 100%;

    .banner {
        background: url('../../public/images/reward/bg.png') center no-repeat;
        background-size: 100% 100%;
        padding: 44px 120px;

        .banner-name {
            flex: 1;
            padding-top: 68px;
            font-size: 20px;
            color: #FFFFFF;
            line-height: 24px;

            div {
                margin: 32px 0;
                font-size: 56px;
                color: #C9FA5B;
                font-weight: 600;
                line-height: 56px;
            }

            p {
                &:last-child {
                    font-size: 16px;
                    color: rgba(255, 255, 255, 0.8);
                }
            }
        }

        img {
            flex: 0 0 433px;
            width: 433px;
            height: 350px;
        }
    }


    .reward-title {
        margin-top: 20px;
        justify-content: center;

        img {
            flex: 0 0 95px;
            width: 95px;
            height: 17px;
            margin-top: 1px;
        }

        div {
            padding: 0 8px;
            font-size: 20px;
            color: #C9FA5B;
            font-weight: 600;
            line-height: 20px;
        }
    }

    .reward-desc {
        margin-top: 10px;
        font-size: 14px;
        color: #989898;
        text-align: center;
    }

    .reward-nav {
        border-bottom: 1px solid rgba(238, 238, 238, 0.2);

        .nav-con {
            width: 1240px;
            margin: 0 auto;

            div {
                margin-right: 36px;
                padding: 20px 0;
                font-size: 16px;
                color: rgba(255, 255, 255, 0.6);
                cursor: pointer;

                &:last-child {
                    margin-right: 0;
                }

                &:hover {
                    color: #fff;
                    border-bottom: 2px solid rgba(201, 250, 91, 1);
                }
            }

            .actnav {
                color: #fff;
                border-bottom: 2px solid rgba(201, 250, 91, 1);
            }
        }
    }

    .bilnd-con {
        width: 1240px;
        padding: 20px 0 180px;
        margin: 0 auto;

        .reword-list {
            position: relative;
            margin-bottom: 20px;
            padding: 32px 20px 32px 32px;
            background: url('../../public/images/reward/bg1.png') center no-repeat;
            background-size: 100% 100%;
            border-radius: 16px;

            .tag {
                position: absolute;
                top: -8px;
                right: 0;
                width: 78px;
                height: 64px;
                background: url('../../public/images/reward/tabbg.png') center no-repeat;
                background-size: 100% 100%;

                p {
                    text-align: center;
                    font-size: 12px;
                    color: #000000;
                    line-height: 12px;
                    font-weight: 600;
                    margin-top: 26px;
                    transform: rotate(-13deg);
                }
            }

            &:last-child {
                margin-bottom: 0;
            }

            .list-logo {
                flex: 0 0 124px;
                margin-right: 48px;

                img {
                    width: 124px;
                    height: 124px;
                }
            }

            .list-info {
                flex: 1;

                .info-name {
                    font-size: 20px;
                    color: #FFFFFF;
                    line-height: 20px;

                    p {
                        margin-left: 8px;
                        height: 20px;
                        padding: 0 8px;
                        font-size: 12px;
                        color: #B2F51C;
                        background: rgba(178, 245, 28, .1);
                        border-radius: 4px;
                    }
                }

                .info-desc {
                    flex-wrap: wrap;

                    .desc {
                        flex: 0 0 33.3%;
                        margin-top: 20px;

                        div {
                            font-size: 12px;
                            color: rgba(255, 255, 255, 0.6);
                        }

                        &:nth-last-child(-n + 2) {
                            p {
                                color: #B2F51C;
                            }
                        }

                        p {
                            margin-top: 8px;
                            font-size: 16px;
                            color: #FFFFFF;
                            padding-right: 6px;

                            .block {
                                font-size: 16px;
                                color: #B2F51C;
                            }

                            .colon {
                                padding: 0 6px;
                                font-size: 16px;
                                color: #fff;
                            }
                        }
                    }
                }
            }

            .list-btn {
                flex: 0 0 266px;
                margin-top: 53px;
                margin-left: 48px;
                justify-content: flex-end;
                flex-wrap: wrap;

                .flexcenter {
                    flex: 0 0 125px;
                    height: 40px;
                    overflow: hidden;
                    margin-top: 16px;
                    margin-left: 16px;
                    cursor: pointer;
                    text-align: center;

                    &:nth-child(1),
                    &:nth-child(3) {
                        margin-left: 0;
                    }

                    &:nth-child(-n + 2) {
                        margin-top: 0;
                    }
                }

                .bg1 {
                    background: url('../../public/images/reward/btnbg1.png') center no-repeat;
                    background-size: 100% 100%;
                    font-size: 14px;
                    color: #000000;
                }

                .bg2 {
                    background: url('../../public/images/reward/btnbg2.png') center no-repeat;
                    background-size: 100% 100%;
                    font-size: 14px;
                    color: #000000;
                }

                .bg3 {
                    background: url('../../public/images/reward/btnbg3.png') center no-repeat;
                    background-size: 100% 100%;
                    font-size: 14px;
                    color: #FFFFFF;
                }

                .bg4 {
                    background: url('../../public/images/reward/btnbg4.png') center no-repeat;
                    background-size: 100% 100%;
                    color: #fff;
                    font-size: 14px;
                    filter: brightness(0.5);

                }
            }
        }

        .linebg2 {
            background: #181818;

            .list-info {
                .info-desc {
                    width: 80%;

                    .desc {
                        flex: 0 0 40%;

                        &:nth-child(2n) {
                            flex: 0 0 60%;
                        }
                    }
                }
            }

            .list-btn {
                margin-top: 110px;
            }
        }

        .linebg5 {
            .list-btn {
                margin-top: 53px;
            }
        }

        .linebg3 {

            background: linear-gradient(180deg, rgba(189, 114, 255, .2), rgba(120, 1, 255, .2));

            .list-info {
                .info-desc {


                    .desc {

                        &:nth-child(2) {
                            flex: 0 0 66.6%;
                        }
                    }
                }
            }
        }

        .linebg4 {

            background: linear-gradient(180deg, rgba(255, 204, 71, .2) -10.71%, rgba(255, 168, 0, .2) 84.58%);

            .list-info {
                .info-desc {
                    width: 80%;

                    .desc {
                        flex: 0 0 40%;

                        &:nth-child(2n) {
                            flex: 0 0 60%;
                        }
                    }
                }
            }


        }

        .linebg6 {
            background: url('../../public/images/reward/planetbg.png') center no-repeat;
            background-size: 100% 100%;

            .list-info {
                .info-desc {
                    width: 80%;

                    .desc {
                        flex: 0 0 40%;

                        &:nth-child(2n) {
                            flex: 0 0 60%;
                        }
                    }
                }
            }
        }

        .linebg7 {
            background: url('../../public/images/reward/planetbg2.png') center no-repeat;
            background-size: 100% 100%;

            .list-info {
                .info-desc {
                    width: 80%;

                    .desc {
                        flex: 0 0 40%;

                        &:nth-child(2n) {
                            flex: 0 0 60%;
                        }
                    }
                }
            }
        }
    }
}

::v-deep .van-popup {
    border-radius: 20px;
    overflow-y: initial;
}

.notice-con {
    position: relative;
    width: 684px;
    padding: 40px 0;
    max-height: 653px;
    background: url("../../public/images/reward/rulesbg.png") top no-repeat;
    background-size: 100% 653px;

    &::after {
        content: '';
        position: absolute;
        top: -30px;
        right: 45px;
        width: 154px;
        height: 158px;
        background: url("../../public/images/reward/rules.png") top no-repeat;
        background-size: 100% 100%;
    }

    .close {
        position: absolute;
        bottom: -58px;
        left: 50%;
        width: 38px;
        height: 38px;
        transform: translateX(-50%);
        cursor: pointer;
    }

    .notice-name {
        padding: 20px 40px 0;
        margin-bottom: 32px;
        font-size: 24px;
        color: #c9fa5b;
        line-height: 24px;
    }

    .notice-title {
        padding: 0 40px 12px;
        font-size: 18px;
        color: #ffffff;
    }


    .rules-list {
        max-height: 330px;
        overflow-y: scroll;
        padding: 0 40px;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.8);

        div {
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }

            label {
                color: #C9FA5B;
            }
        }

        &::-webkit-scrollbar {
            width: 0px; //修改滚动条宽度
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 0;
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            background: #666666;
        }

        .rules-table {

            .table-title {
                padding: 20px;
                background: #232323;
                margin-bottom: 0;

                div {
                    flex: 0 0 20%;
                    font-size: 13px;
                    color: rgba(255, 255, 255, 0.6);
                    margin-bottom: 0;

                    &:nth-child(2) {
                        flex: 1;
                    }

                    &:last-child {
                        flex: 0 0 30%;
                        text-align: right;
                    }
                }
            }

            .tablle-info {
                padding: 20px;
                margin-bottom: 0;

                div {
                    flex: 0 0 20%;
                    font-size: 13px;
                    color: #fff;
                    margin-bottom: 0;

                    &:nth-child(2) {
                        flex: 1;
                    }

                    &:last-child {
                        flex: 0 0 30%;
                        text-align: right;
                    }
                }
            }
        }

        .montitle {
            padding: 20px;
            background: #232323;
            margin-bottom: 0;

            div {
                flex: 0 0 50%;
                margin-bottom: 0;
                font-size: 13px;
                color: rgba(255, 255, 255, 0.6);

                &:last-child {
                    text-align: right;
                }
            }
        }

        .montime {
            padding: 20px;

            div {
                flex: 0 0 50%;
                margin-bottom: 0;
                font-size: 13px;
                color: #fff;

                &:last-child {
                    text-align: right;
                }
            }
        }
    }

    .notice-time {
        padding: 23px 40px 0;
        font-size: 16px;
        text-align: right;
        color: rgba(255, 255, 255, 0.6);
    }

    .notice-btn {
        margin: 23px auto 0;
        width: 418px;
        height: 52px;
        background: url("../../public/images/new/btnbg25.png") center no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
        font-size: 18px;
        color: #000000;
        text-align: center;
        line-height: 52px;
    }
}

.speedcon {
    position: relative;
    width: 816px;
    padding: 32px;

    .close {
        position: absolute;
        top: 32px;
        right: 32px;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }

    .speedcon-title {
        margin-bottom: 24px;
        font-size: 20px;
        color: #FFFFFF;
        line-height: 25px;

        img {
            width: 24px;
            height: 24px;
            margin-right: 8px;
        }
    }

    .speed-wrappar {
        max-height: 400px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
            background-color: rgba(255, 255, 255, .4);
        }

        /*定义滚动条轨道
 内阴影+圆角*/
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            background-color: rgba(255, 255, 255, .5);
        }

        /*定义滑块
     内阴影+圆角*/
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
            background-color: rgba(0, 0, 0, .5);
        }
    }

    .speed-desc {
        margin-bottom: 24px;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.80);
    }

    .rate-table {
        margin-bottom: 24px;
        border: 1px solid rgba(255, 255, 255, 0.08);
        box-sizing: border-box;

        .table-thead {
            background: rgba(201, 250, 91, 0.10);

            div {
                flex: 0 0 25%;
                height: 39px;
                font-size: 12px;
                color: rgba(255, 255, 255, 0.85);
                text-align: center;
                border-right: 1px solid rgba(255, 255, 255, 0.08);

                &:last-child {
                    border-right: 0;
                }
            }
        }

        .tbody-info {
            border-top: 1px solid rgba(255, 255, 255, 0.08);

            div {
                flex: 0 0 25%;
                height: 39px;
                font-size: 12px;
                color: rgba(255, 255, 255, 0.85);
                text-align: center;
                border-right: 1px solid rgba(255, 255, 255, 0.08);
                line-height: 39px;

                &:last-child {
                    border-right: 0;
                }
            }
        }
    }

    .ratebtn {
        width: 231px;
        height: 44px;
        margin: 24px auto 0;
        border-radius: 6px;
        background: #C9FA5B;
        font-size: 20px;
        color: #282626;
        font-weight: 600;
        cursor: pointer;
    }
}

.no-data {
    width: 100%;
    padding: 130px 0;

    img {
        display: block;
        width: 120px;
        height: 120px;
        margin: 0 auto 16px;
    }

    span {
        display: block;
        margin: 0 auto;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #a1a1a1;
    }
}

@media (max-width:1200px) {
    .blind {
        padding: 88px 0 70px;

        .banner {
            justify-content: center;
            padding: 20px 0 50px;
            flex-wrap: wrap;

            .banner-name {
                padding-top: 0;
                flex: 0 0 100%;
                text-align: center;
            }

            img {
                flex: 0 0 170px;
                width: 170px;
                height: 137px;
                margin-top: 16px;
            }
        }

        .reward-nav {
            .nav-con {
                padding: 0 16px;
                width: 100%;
            }

        }

        .bilnd-con {
            width: calc(100vw - 8px);

            .reword-list {
                padding: 16px;
                flex-wrap: wrap;

                .list-logo {
                    margin-right: 16px;
                }

                .list-info {
                    .info-desc {
                        .desc {
                            flex: 0 0 50% !important;
                        }
                    }
                }

                .list-btn {
                    margin-top: 16px;
                    flex: 0 0 100%;
                    justify-content: flex-start;

                    .flexcenter {
                        margin-top: 0;

                        &:first-child {
                            margin-left: 85px;
                        }

                        &:nth-child(3) {
                            margin-left: 16px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:767px) {
    .blind {
        padding: 56px 0;

        .banner {
            .banner-name {
                p {
                    font-size: 14px;
                    line-height: 14px;
                }

                div {
                    padding: 0 16px;
                    margin: 16px 0;
                    font-size: 32px;
                    line-height: normal;
                }
            }
        }

        .reward-nav {
            .nav-con {
                div {
                    padding: 16px 0;
                    font-size: 14px;
                    line-height: 14px;
                }
            }
        }

        .bilnd-con {
            .reword-list {
                background: #181818;

                .list-logo {
                    flex: 0 0 100%;
                    margin-right: 0;
                    text-align: center;

                }

                .list-info {
                    flex: 0 0 100%;
                    margin-top: 16px;

                    .info-name {
                        font-size: 16px;

                        p {
                            display: none;
                        }
                    }

                    .info-desc {
                        width: 100% !important;

                        .desc {
                            margin-top: 16px;

                            p {
                                font-size: 14px;

                                .block,
                                .colon {
                                    font-size: 14px;
                                }

                                .colon {
                                    padding: 0 2px;
                                }
                            }
                        }
                    }
                }

                .list-btn {
                    margin-left: 0;

                    .flexcenter {
                        flex: 0 0 48%;
                        margin-left: 0;

                        &:first-child,
                        &:nth-child(3) {
                            margin-left: 0;
                        }

                        &:nth-child(2n) {
                            margin-left: 4%;
                        }

                        &:nth-child(-n + 2) {
                            margin-bottom: 12px;
                        }
                    }
                }
            }

            .lenwid {
                .list-btn {
                    .bg3 {
                        flex: 0 0 100%;
                        background: url('../../public/images/reward/btnbg7.png') center no-repeat;
                        background-size: 100% 100%;
                    }
                }
            }

        }
    }

    .notice-con {
        width: calc(100vw - 8px);
        padding: 20px 0;

        &::after {
            display: none;
        }

        .close {
            bottom: -40px;
            width: 28px;
            height: 28px;
        }

        .notice-name {
            padding: 0 16px;
            margin-bottom: 12px;
            font-size: 16px;
        }

        .notice-title {
            padding: 0 16px 8px;
            font-size: 14px;
        }

        .rules-list {
            padding: 0 16px;
            font-size: 14px;

            div {
                margin-bottom: 8px;
            }
        }

        .notice-time {
            padding: 23px 16px 0;
            font-size: 14px;
        }

        .notice-btn {
            width: 262px;
            height: 40px;
            margin: 12px auto 0;
            background: url("../../public/images/new/btnbg26.png") top no-repeat;
            background-size: 100% 100%;
            font-size: 14px;
            line-height: 38px;
        }
    }

    .speedcon {
        width: calc(100vw - 8px);
        padding: 20px;

        .speed-desc {
            font-size: 14px;
            margin-bottom: 16px;
        }

        .rate-table {
            width: 100%;

            .table-thead {
                background: rgba(201, 250, 91, 0.10);

                div {
                    flex: 0 0 25%;
                    height: inherit;
                    padding: 12px 6px;
                    font-size: 12px;
                }
            }
        }
    }
}</style>